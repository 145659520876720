<template>
    <v-app-bar absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
        <v-app-bar-nav-icon class="icon"></v-app-bar-nav-icon>

        <v-toolbar-title class="title">JossueAzzad.dev</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-breadcrumbs class="breadcrumbs" :items="items" large></v-breadcrumbs>
    </v-app-bar>
</template>


<script>
export default {
    data: () => ({
        items: [
            {
                text: 'Home',
                disabled: true,
                
            },
            {
                text: 'About',
                disabled: true,
            },
            {
                text: 'Projects',
                disabled: true,
            },
            {
                text: 'Contact',
                disabled: true,
            },
        ],
    }),
}
</script>

<style lang="scss" scoped>
@import "@/responsive";

.title {
    font-weight: bold;
}

.breadcrumbs {
    cursor: pointer;

    @include responsive(mobile) {
        display: none;
    }
}

.icon{
    @include responsive(desktop) {
        display: none;
    }
}
</style>