<template>
  <v-app>
    <MenuBar />
    <Portfolio class="portfolio" />
  </v-app>
</template>

<script>
import MenuBar from './components/MenuBar';
import Portfolio from '@/views/PortfolioView.vue'

export default {
  name: 'App',

  components: {
    MenuBar,
    Portfolio
  },

  data: () => ({
    //
  }),
};
</script>
