<template>
    <div class="portfolio">
        <section class="hero">
            <div class="container">
                <div class="photo-movil">
                    <v-img class="rounded-circle" cover lazy-src="https://picsum.photos/id/11/10/6" height="200" width="200"
                        :src=profileImage></v-img>
                </div>
                <div class="description">
                    <div class="profession">{{ profession }}</div>
                    <div class="name">{{ fullName }}
                        <span class="mdi mdi-map-marker"></span>
                    </div>
                    <div class="social">
                        <v-btn href="https://github.com/JAzzad5"
                            target="_blank" icon>
                            <v-icon large color="black">
                                mdi mdi-github
                            </v-icon>
                        </v-btn>
                        <v-btn href="https://www.linkedin.com/in/jossu%C3%A9-azzad-aa5855103/"
                            target="_blank" icon>
                            <v-icon large color="black">
                                mdi mdi-linkedin
                            </v-icon>
                        </v-btn>

                    </div>

                </div>
                <div class="photo">
                    <v-img class="rounded-circle" cover :lazy-src=profileImage height="200" width="200"
                        :src=profileImage></v-img>
                </div>
            </div>
        </section>

        <section class="about">
            <div class="container-about">
                <h2 class="pt-5 mr-3">Tech Stack |</h2>
                <div class="stack-list">
                    <v-tooltip bottom color="#e44d26">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img v-bind="attrs" v-on="on" class="mr-2 stack" max-height="35" max-width="35"
                                src="@/assets/html.svg"></v-img>
                        </template>
                        <span>HTML5</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#1172b8">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img v-bind="attrs" v-on="on" class="mr-2 stack" max-height="35" max-width="35"
                                src="@/assets/css.svg"></v-img>
                        </template>
                        <span>CSS3</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#ffca28">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img v-bind="attrs" v-on="on" class="mr-2 stack" max-height="35" max-width="35"
                                src="@/assets/javascript.svg"></v-img>
                        </template>
                        <span>Javascript</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#41b883">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img v-bind="attrs" v-on="on" class="mr-2 stack" max-height="35" max-width="35"
                                src="@/assets/vue.svg"></v-img>
                        </template>
                        <span>Vue</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#e23237">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img v-bind="attrs" v-on="on" class="mr-2 stack" max-height="35" max-width="35"
                                src="@/assets/angular.svg"></v-img>
                        </template>
                        <span>Angular</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#8cc84b">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img v-bind="attrs" v-on="on" class="mr-2 stack" max-height="35" max-width="35"
                                src="@/assets/node.svg"></v-img>
                        </template>
                        <span>NodeJs</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#fcca3f">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img v-bind="attrs" v-on="on" class="mr-2 stack" max-height="35" max-width="35"
                                src="@/assets/firebase.svg"></v-img>
                        </template>
                        <span>Firebase</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#439a45">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img v-bind="attrs" v-on="on" class="mr-2 stack" max-height="35" max-width="35"
                                src="@/assets/mongo.svg"></v-img>
                        </template>
                        <span>MongoDB</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#000000">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img v-bind="attrs" v-on="on" class="mr-2 stack" max-height="35" max-width="35"
                                src="@/assets/github.svg"></v-img>
                        </template>
                        <span>Github</span>
                    </v-tooltip>
                </div>

            </div>

        </section>

        <!-- Add more sections as needed -->

        <footer>
            <div class="container">
                <p>&copy; {{ currentYear }} {{ footer }}</p>
            </div>
        </footer>
    </div>
</template>
  
<script>
export default {
    name: 'PortfolioView',

    data() {
        return {
            fullName: "Hi , I'm Jossué Azzad, passionate Systems Engineer & Developer, based in Tegucigalpa Honduras.",
            profession: 'Systems Engineer | Backend Developer',
            about: 'Write a brief description about yourself.',
            footer: "All rights reserved, Jossué Azzad.",
            profileImage: 'https://avatars.githubusercontent.com/u/25910435?s=400&u=35e4b4ad8cce2bdb28cba6b88ddb9994db40e70d&v=4',
            currentYear: new Date().getFullYear()
        };
    },


};
</script>


<style lang="scss" scoped>
@import "@/responsive";

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    height: 100vh;
}

.container {
    max-width: 100% !important;
    margin: 0 auto;
}

/* Portfolio Styles */
.portfolio {
    background-color: transparent;
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.hero {
    padding: 0;
    padding-top: 20vh;
    flex: 1;
    display: flex;
    align-items: center;


    @include responsive(mobile) {
        padding-top: 10vh;
    }
}

.hero .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include responsive(mobile) {
        flex-direction: column;
        padding: 30px;
    }

    @media (max-width: 1000px) {
        flex-direction: column;
        padding: 30px;
    }

}

.photo {
    margin-left: 50px;

    @include responsive(mobile) {
        display: none;
    }

    @media (max-width: 1000px) {
        display: none;
    }
}

.photo-movil {
    @include responsive(mobile) {
        border-radius: 50%;
        margin-bottom: 30px;
    }

    @media (min-width: 1000px) {
        display: none;
    }
}

.container .photo img {
    border-radius: 50%;
}

.hero-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
}

.hero-text {
    width: 50%;
}

.profession {
    display: flex;
    justify-content: start;
    color: #1B2812;
    font-size: 32px;
    margin: 0;
    font-weight: bold;
}

.name {
    font-size: 20px;
    max-width: 500px;
    margin: 10px 0;
    color: #908f91;
}

.name svg {
    max-width: 12px;

}

.about {
    color: #1a1a1a;
    padding: 40px 0;
    flex: 1;

    @include responsive(mobile) {
        padding-top: 0;
    }

}

.about h2 {
    font-size: 24px;
    margin: 0 0 20px;
}

.about-text {
    font-size: 18px;
    line-height: 1.5;
}

footer {
    background-color: #111;
    padding: 20px 0;
    text-align: center;
    flex-shrink: 0;
}

footer p {
    margin: 0;
    font-size: 14px;
}

.social {
    margin-top: 25px;
}

.social svg {
    max-width: 25px;
    margin-right: 10px;
    cursor: pointer;
}

.container-about {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @include responsive(mobile) {
        flex-direction: column;
        padding: 30px;
    }


}

.container-about .stack {
    cursor: pointer;
}

.stack-list {
    display: flex;
    justify-content: center;

    @include responsive(mobile) {
        width: 100%;
        box-sizing: content-box;
        display: flex;
        justify-content: center;
        padding: 30px;
        padding-top: 0;
    }

}

.stack-list .stack {
    max-width: 30px !important;
}
</style>
  